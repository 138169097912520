import React from 'react';
import SlideLine from '../ui/slideLine';
import BackButton from '../ui/backButton';
import * as style from './articleIntro.module.scss';
import translations from '../../utils/translations';

const ArticleIntro = props => (
  <div
    className={`typeFonts1 text-block ${style.articleIntro} ${
      props.isIE ? style.isIE : ''
    } ${props.small ? style.small : ''}`}
  >
    <div className={style.articleIntroText}>
      <div className={style.backButtonWrap}>
        {props.backButton && <BackButton history={props.history} />}
      </div>
      {props.archive && (
        <div className={style.articleIntroCategory}><a href={props.archive.path}>{props.archive.title}</a></div>
      )}
      <h1>{props.title}</h1>
      {props.authors && (
        <div className={style.articleIntroAuthors}>{translations.t('By')} {props.authors.map(author => author.name).join(' / ')}</div>
      )}
			<div className={style.articleIntroDate}>
      	{new Intl.DateTimeFormat(
	      	'en-US',
	      	{
		      	year: 'numeric',
		      	month: 'long',
		      	day: '2-digit'
		      }
				).format((props.created_date * 1000))}
      </div>
      <SlideLine />
    </div>
  </div>
);

export default ArticleIntro;
