// extracted by mini-css-extract-plugin
export var articleIntro = "articleIntro-module--articleIntro--S7rNJ";
export var small = "articleIntro-module--small--IzcDj";
export var articleIntroText = "articleIntro-module--articleIntroText--N-9Bz";
export var fadeInTransform = "articleIntro-module--fadeInTransform--bHY-Y";
export var fadeInBlur = "articleIntro-module--fadeInBlur--f4egO";
export var articleIntroCategory = "articleIntro-module--articleIntroCategory--I4scQ";
export var articleIntroAuthors = "articleIntro-module--articleIntroAuthors--ZmEbS";
export var articleIntroDate = "articleIntro-module--articleIntroDate--ifOUJ";
export var isIE = "articleIntro-module--isIE--Wv0jf";
export var slideLeft = "articleIntro-module--slideLeft--96uSa";
export var backButtonWrap = "articleIntro-module--backButtonWrap--1Phkt";
export var slideUp = "articleIntro-module--slideUp--KPEZ3";
export var slideUpLarge = "articleIntro-module--slideUpLarge--LKv9m";
export var fadeIn = "articleIntro-module--fadeIn--V+o-z";