import React from 'react';
import * as style from './slideLine.module.scss';

const SlideLine = () => (
  <svg className={style.svg}>
    <line x1="0" y1="1" x2="125" y2="1" />
  </svg>
);

export default SlideLine;
