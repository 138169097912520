import React from 'react';
import CircleButton from './circleButton';
import { navigate } from 'gatsby';

const BackButton = () => (
  <CircleButton
    handleClick={() => {
      navigate(-1);
    }}
    type="left"
    text="back"
    textRight
    textWhite
  />
);

export default BackButton;
